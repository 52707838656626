import { graphql } from "gatsby";
import Link from "gatsby-link";
import { get, kebabCase } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { EVENTS } from "@upsolve/shared";
import { H1, H5, HR, P, Small } from "@upsolve/ui";
import styled from "styled-components";
import * as CONTENT_CREATION_METHODS from "../components/analytics/contentCreationMethods";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import Article from "../components/Layout/Article.div";
import AuthorBio from "../components/Layout/AuthorBio";
import AuthorsBlock from "../components/Layout/AuthorsBlock";
import CTASideBySideOfferings from "../components/CTA/CTASideBySideOfferings";
import CitationsList from "../components/Layout/CitationsList";
import ContentSectionFooter from "../components/Layout/ContentSectionFooter";
import DisabledReadingPrompt from "../components/CTA/DisabledReadingPrompt";
import HeroFilerCta from "../components/CTA/HeroFilerCta";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import RelatedArticles from "../components/Programmatic/RelatedArticles";
import SEO from "../components/SEO/SEO";
import TableOfContents from "../components/Layout/TableOfContents";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import UpsolveTextBio from "../components/ProofBuilders/UpsolveTextBio";
import VideoInContext from "../components/Media/VideoInContext";
import checkForInlineCta from "../components/Contentful/checkForInlineCta";
import getCitationsFromRichText from "../components/Contentful/getCitationsFromRichText";
import getHeadersFromRichText from "../components/Contentful/getHeadersFromRichText";
import getWordCountFromRichText from "../components/Contentful/getWordCountFromRichText";
import richTextNodeToComponent from "../components/Contentful/richTextNodeToComponent";
import richTextNodesInSections from "../components/Contentful/richTextNodesInSections";
import { track } from "../components/analytics/track";
import withGeoLocation from "../components/maps/withGeoLocation";
import withScrollWatcher from "../components/analytics/withScrollWatcher";
import { Ampify } from "../components/AMP/AmpContext";
import { AJMArticleSlugs } from "../components/CTA/TempAJMArticles";
import { CTATarget } from "../components/CTA/getCTAData";
import CTAUpsolveReviews from "../components/CTA/CTAUpsolveReviews";
import { learnTagsSortedByPriority } from "../components/Learn/learnTagPriority";
import { $TSFixMe, TTrackingEventPrimaryIds, TTrackingEventProperties } from "@upsolve/shared/dist/types";
import { ProductSelectorModal } from "../components/Forms/ProductSelectorModal";

const contentGroup = CONTENT_GROUPS.LEARN_ARTICLE;
const templateProps = {
  pageComponentName: "LearnArticle",
  pageComponentVersion: "3",
};

const getArticleTarget = (slug, tags) => {
  //future: use the graphQL query instead of this static list
  if (AJMArticleSlugs.find((ajmSlug) => slug.includes(ajmSlug))) {
    return CTATarget.AJM;
  } else if (tags.find((tag) => tag.value === learnTagsSortedByPriority.STUDENT_LOANS)) {
    return CTATarget.STUDENT_LOANS;
  } else if (tags.find((tag) => tag.value === learnTagsSortedByPriority.CONSUMER_RIGHTS)) {
    return CTATarget.DEBT_TRIAGE;
  }
  return CTATarget.SCREENER;
};

/**
 * LearnArticle
 * - v0: Where we began.
 * - v1: Added geolocation region to persistent cta text
 * - v2: Layout adjustments - condensed top, collapsed TOC, hide CTAs based on screen width
 * - v3: ContentSectionFooter removed, share button at top added, more intuitive Table of Contents
 *
 * @component
 * @version 3
 */
type LearnArticleProps = {
  data: { article: $TSFixMe; relatedArticles: { nodes: $TSFixMe } };
  geoLocation: { regionName: string };
  pageContext: { amp: boolean; slug: string };
  scrollWatcher: $TSFixMe;
};
const LearnArticle = (props: LearnArticleProps) => {
  const { geoLocation, pageContext, scrollWatcher } = props;
  const { amp, slug } = pageContext;
  const contentNode = props.data.article;
  const seoNode = contentNode.seo;
  const [isDebtTriageOpen, setIsDebtTriageOpen] = useState(false);

  const content = get(contentNode, "content.json");
  const sectionHeaders = getHeadersFromRichText(content);
  const citations = getCitationsFromRichText(content);
  const { video } = contentNode;
  const articleTags = contentNode.tags?.filter((tag) => tag.hasDirectoryPage) || [];
  const hasInlineCta = checkForInlineCta(content.json);
  const cta = `File Bankruptcy${geoLocation && geoLocation.regionName ? ` in ${geoLocation.regionName}` : " for Free"}`;
  const authors = (contentNode.authors || [contentNode.author]).filter((author) => author && author.name != null);
  const wordCount = getWordCountFromRichText(get(props, "data.article.content.json", []));
  const relatedArticles = props.data.relatedArticles.nodes;
  const trackingPageContext = {
    contentCreationMethod: CONTENT_CREATION_METHODS.WRITTEN,
    contentGroup,
    contentLocalized: get(props, "data.article.locality") != null,
    publishedAt: get(props, "data.article.publishedAt"),
    slug,
    tags: articleTags.map((tag) => ({ tag: tag.value })),
    updatedAt: get(props, "data.article.updatedAt"),
    wordCount,
    ...templateProps,
  };

  const ctaTarget = getArticleTarget(slug, articleTags);

  // On Mount
  useEffect(() => {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }, []);

  //Review: @jeff do we really need this or is it just flooding our events
  // - Page Scroll
  const [scrollIntervalCache, setScrollIntervalCache] = useState([0]);
  useEffect(() => {
    if (Math.max(...scrollIntervalCache) < scrollWatcher.scrollInterval) {
      setScrollIntervalCache(scrollIntervalCache.concat(scrollWatcher.scrollInterval));
      track(EVENTS.PAGE_SCROLL, { pageContext: trackingPageContext });
    }
  }, [scrollWatcher.scrollInterval]);

  // Comp
  const children = (
    <TopLevelLayout showPersistentCta={false} persistentCtaText={cta} trackingPageContext={trackingPageContext}>
      <SEO
        seoNode={seoNode}
        contentNode={{
          ...contentNode,
          citations,
          seoImage: contentNode.seoImage || contentNode.coverImage,
          video,
        }}
      />
      <Header trackingPageContext={trackingPageContext} ctaTarget={ctaTarget} />
      <Article>
        <ArticleNav>
          <div>
            <a aria-busy="false" href="/learn/">
              ← Learning Center
            </a>
          </div>
          {articleTags.length > 0 && (
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <div>Tags: </div>
              {articleTags.map((tag, index) => (
                <div key={tag.value} style={{ display: "flex", gap: "8px" }}>
                  {index > 0 ? <div>|</div> : <></>}
                  <a aria-busy="false" href={`/learn/category/${kebabCase(tag.value)}/`}>
                    {tag.label}
                  </a>
                </div>
              ))}
            </div>
          )}
        </ArticleNav>
        <div className="article__body">
          <div className="article__content">
            <div className="content-hero">
              <header className="content-hero__header">
                <H1 as="h1">{contentNode.title}</H1>
                <UpsolveTextBio wordCount={wordCount} ctaTarget={ctaTarget} trackingPageContext={trackingPageContext} />
                <HR />
              </header>
            </div>
            <div className="content-body">
              <article className="content-body__container">
                {get(contentNode, "articleExcerpt.articleExcerpt") != null && (
                  <div className="content-body__lead">
                    <div className="summary">
                      <b>In a Nutshell</b>
                      <P>{get(contentNode, "articleExcerpt.articleExcerpt")}</P>
                    </div>
                  </div>
                )}
                <AuthorsBlock authors={authors} createdAt={contentNode.createdAt} updatedAt={contentNode.updatedAt} />
                <HR />
                {sectionHeaders.length > 1 && <TableOfContents sectionHeaders={sectionHeaders} />}
                {richTextNodesInSections(content).length === 1
                  ? Array.prototype.map.call(richTextNodesInSections(content)[0], (c, i) =>
                      richTextNodeToComponent(c, i, null, { contentGroup, citations, trackingPageContext })
                    )
                  : Array.prototype.map.call(
                      richTextNodesInSections(content),
                      (section, sectionIndex, sectionArray) => (
                        <Fragment key={sectionIndex}>
                          <section>
                            {Array.prototype.map.call(section, (c, i) =>
                              richTextNodeToComponent(c, i, null, { contentGroup, citations, trackingPageContext })
                            )}
                          </section>
                          {hasInlineCta === false && Math.floor(sectionArray.length * 0.35) === sectionIndex && (
                            <CTAUpsolveReviews
                              ctaTarget={ctaTarget}
                              key={"cta reviews"}
                              dataTestId="inline-content-cta"
                              trackingPageContext={trackingPageContext}
                            />
                          )}
                        </Fragment>
                      )
                    )}
                {video != null && (
                  <section className="content-video">
                    <VideoInContext video={video} />
                  </section>
                )}
                <HR />
                {citations.length > 0 && (
                  <Fragment>
                    <H5 as="h2">Sources:</H5>
                    <CitationsList citations={citations} />
                  </Fragment>
                )}
                <ContentSectionFooter />
                <HR />
                {authors.length > 0 && (
                  <div className="author-bios">
                    <div className="written-by">
                      <Small>Written By:</Small>
                    </div>
                    {Array.prototype.map.call(authors, (author) => (
                      <AuthorBio key={author.name} author={author} />
                    ))}
                    <div className="team">
                      <Link to="/team">Read About the Upsolve Team</Link>
                    </div>
                  </div>
                )}
              </article>
              <aside className="article__sidebar">
                <div className="sidebar-hero__cta">
                  <HeroFilerCta trackingPageContext={trackingPageContext} target={ctaTarget} />
                </div>
              </aside>
            </div>
          </div>
        </div>
      </Article>
      <RelatedArticles articles={relatedArticles} />
      <CTASideBySideOfferings trackingPageContext={trackingPageContext} ctaTarget={ctaTarget} />
      <Footer showArticles />
      <DisabledReadingPrompt
        isAllowedToRender={() => scrollWatcher.scrollPosition > scrollWatcher.documentHeight / 2}
        trackingPageContext={trackingPageContext}
        ctaTarget={ctaTarget}
      />
      {isDebtTriageOpen && (
        <ProductSelectorModal trackingPageContext={trackingPageContext} onHide={() => setIsDebtTriageOpen(false)} />
      )}
    </TopLevelLayout>
  );

  return amp === true ? <Ampify>{children}</Ampify> : children;
};
const ArticleNav = styled.nav`
  @media (max-width: 720px) {
    padding-left: 20px;
  }
`;

//TODO: @Jeff, could not figure out why callToActionTag is returning null, and cannot figure out how to test this query on graphiQL. using a constant list for AJM articles for now.
export const pageQuery = graphql`
  query LearnArticleBySlug($slug: String!, $relatedArticleIds: [String]) {
    article: contentfulLearnArticle(slug: { eq: $slug }) {
      slug
      title
      publishedAt
      tags {
        value
        label
        hasDirectoryPage
      }
      coverImage {
        image {
          file {
            url
          }
          fluid(maxWidth: 960) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      articleExcerpt {
        articleExcerpt
      }
      video {
        ...VideoNode
      }
      author {
        ...AuthorNode
      }
      authors {
        ...AuthorNode
      }
      content {
        json
      }
      seo {
        ...SeoNode
      }
      createdAt
      updatedAt
    }
    relatedArticles: allContentfulLearnArticle(filter: { id: { in: $relatedArticleIds }, slug: { ne: $slug } }) {
      nodes {
        createdAt
        slug
        title
        authors {
          name
        }
      }
    }
  }
`;
export default withScrollWatcher(withGeoLocation(LearnArticle));
